$white: #fff;
$black: #000;
$grey: #4c6272;
$grey4: #d8dde0;
$dark-blue: #005eb8;
$yellow: #ffeb3b;
$text-color: #212b32;
$text-secondary: #333333;
$tertiaryMain: #015545;
$primaryAction: #6E3792;
