@import './constants';
@import './colors';

html, body { height: 100%;}

body {
  margin: 0;
}

footer {
  position: sticky;
  top: 100vh;
}

.display {
  &__none {
    display: none;
  }
}

.text {
  &--bold {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
  }

  &--align-center {
    align-items: center;
    display: flex;
  }
}

.section-spacing {
  margin-bottom: 3em;
}

p,
code {
  font-size: 1rem;
  margin: 0;
}

.max-screen-size-wrapper {
  margin: auto;
  max-width: $max-container-width;
}

@page {
  margin-top: 7.5mm;
  margin-left: 0mm;
  margin-right: 0mm;
  margin-bottom: 3.5mm;
} 

@media print
{    
  .no-print, .no-print *
  {
    display: none !important;
  }
  .pagebreak {
    page-break-before: always;
  }
  .nobreak {
    width: 100%;
    display: inline-block;
    page-break-inside: avoid;
  }
}

.react-tel-input .form-control:hover {
  border-color: black;
}
.react-tel-input .form-control:focus {
  border-color: #460074;
  border-width: 2px;
}
