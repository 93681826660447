// Typography
$font-size-large: 2rem;
$font-size-regular: 1.3rem;

// Spacing & layout
$header-height-large: 6rem;
$content-padding-horizontal: 2em;

// Max-width control for large devices
$max-container-width: 960px;
