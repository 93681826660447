@import '../../../styles/colors';


.Header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.closeIcon {
    padding: 10px;
    cursor: pointer;
}

.Tab {
    height: 48px;
    min-height: 48px !important;
}

.TabAdded {
    flex-direction: row-reverse !important;
}

.TabIcon {
    background: $primaryAction;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.TitleBox {
    margin: 0 0 20px 40px;
}

.TableBox {
    margin: 50px 20px 0 20px;
}

.EmptyList {
    text-align: center;
    padding: 40px 0 0 0;
    color: $tertiaryMain;
}